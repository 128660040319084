import React, { useState, useEffect } from "react";

const URL = "https://server-ops-go51.onrender.com/7y4723y7hd/pilots/";

const OpsLimits = () => {
  const [inputValue, setInputValue] = useState("");
  const [pilotOptions, setPilotOptions] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await fetch(URL);
        const pilot = await res.json();
        setPilotOptions(pilot);
        setIsLoaded(true);
      } catch (err) {
        setError(err);
        setIsLoaded(true);
      }
    }

    fetchData();
  }, []);

  const updateOpslimits = async () => {
    try {
      const res = await fetch(`${URL}${inputValue}`, {
        method: "PATCH",
        body: JSON.stringify({
          opslimits: new Date(),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await res.json();
      console.log(data);
      //redirect to "/" - this should use reactrouter
      window.location.replace("/");
    } catch (err) {
      console.error(err);
    }
  };

  const inputParams = {
    type: "text",
    autoComplete: "off",
    id: "opslimits",
    required: true,
  };

  return (
    <div className="cardcaps">
      <form
        onSubmit={(event) => {
          event.preventDefault();
          updateOpslimits();
        }}
      >
        <div>
          <>
            <table className="minimalistBlack">
              <thead>
                <tr>
                  <th>GROUND CONDITION</th>
                  <th>FTIT ºC</th>
                  <th>RPM %</th>
                  <th>OIL PSI</th>
                  <th>RMKS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>START</td>
                  <td>
                    <input
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^680$"
                    />
                  </td>
                  <td>--</td>
                  <td>--</td>
                  <td>
                    <p className="inline-flex">
                      Cold start: oil pressure may be{" "}
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^100$"
                      />{" "}
                      psi up to{" "}
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^1$"
                      />{" "}
                      minute.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>IDLE</td>
                  <td>
                    <input
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^575$"
                    />
                  </td>
                  <td>
                    <input
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^60-76$"
                    />
                  </td>
                  <td>
                    <input
                      className="inline-flex"
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^15$"
                    />
                    (min)
                  </td>
                  <td>
                    <p className="inline-flex">
                      Maximum FTIT in SEC is{" "}
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^650$"
                      />{" "}
                      °C.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>MIL/AB</td>
                  <td>
                    <input
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^965$"
                    />
                  </td>
                  <td>
                    <input
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^94$"
                    />
                  </td>
                  <td>
                    <input
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^30-80$"
                    />
                  </td>
                  <td>
                    <p className="inline-flex">
                      ≥ MIL, oil pressure must inc{" "}
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^15$"
                      />{" "}
                      psi min above IDLE press.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>TRANSIENT</td>
                  <td>
                    <input
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^980$"
                    />
                  </td>
                  <td>
                    <input
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^94$"
                    />
                  </td>
                  <td>
                    <input
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^30-80$"
                    />
                  </td>
                  <td>
                    <p className="inline-flex">
                      Maximum temperature limited to{" "}
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^30$"
                      />{" "}
                      seconds.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>FLUCTUATION</td>
                  <td>
                    <p className="inline-flex">
                      ±
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^10$"
                      />
                    </p>
                  </td>
                  <td>
                    <p className="inline-flex">
                      ±
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^1$"
                      />
                    </p>
                  </td>
                  <td>
                    <p className="inline-flex">
                      ±
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^5$"
                      />
                      IDLE
                    </p>
                    <br />
                    <p className="inline-flex">
                      ±
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^10$"
                      />
                      {">"}IDLE
                    </p>
                  </td>
                  <td>
                    <p>
                      Flux more than 1 instr., or thrust surges, indicate ENG
                      problems. Nozzle flux limit to ±
                      <input
                        className="inline-flex"
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^2$"
                      />
                      ≥MIL and
                      <input
                        className="inline-flex"
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^0$"
                      />
                      % {"<"}MIL.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
          <>
            <br />{" "}
            <table className="minimalistBlack">
              <thead>
                <tr>
                  <th>INFLIGHT CONDITION</th>
                  <th>FTIT ºC</th>
                  <th>RPM %</th>
                  <th>OIL PSI</th>
                  <th>RMKS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>START</td>
                  <td>
                    <input
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^800$"
                    />
                  </td>
                  <td>--</td>
                  <td>--</td>
                  <td>--</td>
                </tr>
                <tr>
                  <td>IDLE</td>
                  <td>--</td>
                  <td>--</td>
                  <td>
                    <input
                      className="inline-flex"
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^15$"
                    />
                    (min)
                  </td>
                  <td>
                    <p className="inline-flex">
                      Oil pressure must
                      <input
                        style={{ width: "2.5cm" }}
                        {...inputParams}
                        pattern="^INCREASE$"
                      />{" "}
                      as RPM increases.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>MIL/AB</td>
                  <td>
                    <input
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^980$"
                    />
                  </td>
                  <td>
                    <input
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^96$"
                    />
                  </td>
                  <td>
                    <input
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^30-80$"
                    />
                  </td>
                  <td>
                    <p className="inline-flex">
                      Maximum temperature limited to{" "}
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^10$"
                      />{" "}
                      seconds.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>TRANSIENT</td>
                  <td>
                    <input
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^1000$"
                    />
                  </td>
                  <td>
                    <input
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^96$"
                    />
                  </td>
                  <td>
                    <input
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^30-80$"
                    />
                  </td>
                  <td>
                    <p>
                      MAX POWER (combat only) limited to
                      <input
                        className="inline-flex"
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^6$"
                      />
                      ' max (per app). Total
                      <input
                        className="inline-flex"
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^60$"
                      />
                      minutes before inspection. Log times on 781.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>FLUCTUATION</td>
                  <td>
                    <p className="inline-flex">
                      ±
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^10$"
                      />
                    </p>
                  </td>
                  <td>
                    <p className="inline-flex">
                      ±
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^1$"
                      />
                    </p>
                  </td>
                  <td>
                    <p className="inline-flex">
                      ±
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^5$"
                      />
                      IDLE
                    </p>
                    <br />
                    <p className="inline-flex">
                      ±
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^10$"
                      />
                      {">"}IDLE
                    </p>
                  </td>
                  <td>
                    <p>
                      Same as GND OPS. Zero oil allowed up to
                      <input
                        className="inline-flex"
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^1$"
                      />
                      {"‘"} during flight less than +
                      <input
                        className="inline-flex"
                        style={{ width: "1cm" }}
                        {...inputParams}
                        pattern="^1$"
                      />
                      g.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
          </>
        </div>
        <div className="columns has-text-center">
          <div className="column has-text-left">
            <table className="minimalistBlack">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} colSpan={2}>
                    AIRSPEED LIMITATIONS
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center", fontWeight: "bold" }}>
                    System or Condition
                  </td>
                  <td style={{ textAlign: "center", fontWeight: "bold" }}>
                    KIAS / MACH
                  </td>
                </tr>
                <tr>
                  <td>Canopy Open or in Transit</td>
                  <td>
                    <p className="inline-flex">
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^70$"
                      />
                      (inc. wind vel.)
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>LG Extend or in Transit</td>
                  <td>
                    <p className="inline-flex">
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^300$"
                      />
                      /
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^.65$"
                      />
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>TEF’s w/ ALT FLAP switch</td>
                  <td rowSpan="2">
                    <p className="inline-flex">
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^400$"
                      />
                      /
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^.85$"
                      />
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>AR Door Opening/Closing</td>
                </tr>
                <tr>
                  <td>AR Door Open</td>
                  <td>
                    <p className="inline-flex">
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^400$"
                      />
                      /
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^.95$"
                      />
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>Flight in Severe Turbulence (+3g)</td>
                  <td>
                    <p className="inline-flex">
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^500$"
                      />
                      KIAS
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>Thunderstorm Penetration</td>
                  <td>
                    <p className="inline-flex">
                      <input
                        style={{ width: "1.1cm" }}
                        {...inputParams}
                        pattern="^300$"
                      />
                      KIAS or Opt Cruise
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>Cable Arrestment - Routine</td>
                  <td>
                    <p className="inline-flex">
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^135$"
                      />
                      KIAS
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>Cable Arrestment - Emergency</td>
                  <td>
                    <p className="inline-flex">
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^150$"
                      />
                      KIAS
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="minimalistBlack no-top-border">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} colSpan={2}>
                    ACCELERATION LIMITATIONS (G’s)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center", fontWeight: "bold" }}>
                    Configuration
                  </td>
                  <td style={{ textAlign: "center", fontWeight: "bold" }}>
                    Symm / Asymm
                  </td>
                </tr>
                <tr>
                  <td>Takeoff & Landing</td>
                  <td>
                    <p className="inline-flex">
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^4$"
                      />
                      /
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^2$"
                      />
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>LG Extension & Retraction</td>
                  <td>
                    <p className="inline-flex">
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^2$"
                      />
                      /
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^2$"
                      />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="minimalistBlack no-top-border">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} colSpan={2}>
                    MISCELLANEOUS
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Negative G’s - AB</td>
                  <td>
                    <p className="inline-flex">
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^10$"
                      />
                      “ Max
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>Negative G’s - ≤ MIL</td>
                  <td>
                    <p className="inline-flex">
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^30$"
                      />
                      “ Max
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>Hydraulic Pressure - Minimum</td>
                  <td>
                    <p className="inline-flex">
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^2850$"
                      />
                      PSI
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>Hydraulic Pressure - Maximum</td>
                  <td>
                    <p className="inline-flex">
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^3250$"
                      />
                      PSI
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="minimalistBlack no-top-border">
              <tbody>
                <tr>
                  <td style={{ textAlign: "center", fontWeight: "bold" }}>
                    CROSSWIND
                  </td>
                  <td style={{ textAlign: "center", fontWeight: "bold" }}>
                    Dry / Wet or Night
                  </td>
                </tr>
                <tr>
                  <td>MR Pilots</td>
                  <td>
                    <p className="inline-flex">
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^25$"
                      />
                      /
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^20$"
                      />
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>Formation Takeoff</td>
                  <td>
                    <p className="inline-flex">
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^15$"
                      />
                      /
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^15$"
                      />
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>Formation Landing</td>
                  <td>
                    <p className="inline-flex">
                      <input
                        style={{ width: "1.5cm" }}
                        {...inputParams}
                        pattern="^15$"
                      />
                      / NA
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="column">
            <p style={{ fontWeight: "bold", textDecorationLine: "underline" }}>
              Jet Fuel Starter Limits:
            </p>
            <p style={{ fontWeight: "bold" }}>Normal Ground Ops:</p>
            <p>
              Continuous motoring of the engine shall not exceed{" "}
              <input
                className="inline-flex"
                style={{ width: "1cm" }}
                {...inputParams}
                pattern="^4$"
              />{" "}
              minutes. After this time a normal engine start may be initiated
              after{" "}
              <input
                className="inline-flex"
                style={{ width: "1cm" }}
                {...inputParams}
                pattern="^5$"
              />{" "}
              minutes of cooling. A minimum wait of{" "}
              <input
                className="inline-flex"
                style={{ width: "1cm" }}
                {...inputParams}
                pattern="^1$"
              />{" "}
              minute is required after each JFS start attempt to allow for fuel
              drainage.
            </p>
            <p style={{ fontWeight: "bold" }}>Hot Start of Engine:</p>

            <p>
              Motor until FTIT is below{" "}
              <input
                className="inline-flex"
                style={{ width: "1.5cm" }}
                {...inputParams}
                pattern="^200$"
              />{" "}
              °C.
            </p>

            <p style={{ fontWeight: "bold" }}>Airstart/In Flight:</p>

            <p>
              Below 20,000 feet MSL and 400 knots,{" "}
              <input
                className="inline-flex"
                style={{ width: "1cm" }}
                {...inputParams}
                pattern="^3$"
              />{" "}
              minutes maximum run time when the engine is operating
              satisfactorily above{" "}
              <input
                className="inline-flex"
                style={{ width: "1cm" }}
                {...inputParams}
                pattern="^60$"
              />{" "}
              % rpm; otherwise, unlimited.
            </p>

            <p style={{ fontWeight: "bold", textDecorationLine: "underline" }}>
              PRI and SEC Airstart Envelopes:
            </p>
            <p style={{ fontWeight: "bold" }}>Above 20,000 feet MSL:</p>
            <table className="unstyledTable">
              <tbody>
                <tr>
                  <td style={{ padding: 4 }} rowSpan={2}>
                    Optimum airspeed:
                  </td>
                  <td>
                    PRI ={" "}
                    <input
                      className="inline-flex"
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^250$"
                    />{" "}
                    KIAS
                  </td>
                </tr>
                <tr>
                  <td>
                    SEC ={" "}
                    <input
                      className="inline-flex"
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^275$"
                    />{" "}
                    KIAS
                  </td>
                </tr>
              </tbody>
            </table>

            <p style={{ fontWeight: "bold" }}>
              Below 20,000 feet MSL with JFS on:
            </p>
            <table className="unstyledTable">
              <tbody>
                <tr>
                  <td style={{ padding: 4 }} rowSpan={2}>
                    Minimum airspeed:
                  </td>
                  <td>
                    PRI ={" "}
                    <input
                      className="inline-flex"
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^170$"
                    />{" "}
                    KIAS
                  </td>
                </tr>
                <tr>
                  <td>
                    SEC ={" "}
                    <input
                      className="inline-flex"
                      style={{ width: "1.5cm" }}
                      {...inputParams}
                      pattern="^200$"
                    />{" "}
                    KIAS
                  </td>
                </tr>
              </tbody>
            </table>

            <p style={{ fontWeight: "bold", textDecorationLine: "underline" }}>
              Engine Problem Analysis:
            </p>

            <p>
              Analyze all potential engine problems by checking the following:{" "}
              <input
                className="inline-flex"
                style={{ width: "1.5cm" }}
                {...inputParams}
                pattern="^RPM$"
              />
              ,{" "}
              <input
                className="inline-flex"
                style={{ width: "1.5cm" }}
                {...inputParams}
                pattern="^FTIT$"
              />
              , and{" "}
              <input
                className="inline-flex"
                style={{ width: "5cm" }}
                {...inputParams}
                pattern="^THROTTLE RESPONSE$"
              />
              .
            </p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <div className="submit-form">
              <div className="select">
                <select
                  required
                  value={inputValue}
                  style={{ width: "4cm" }}
                  onChange={(event) => setInputValue(event.target.value)}
                >
                  <option value="">SELECT TRIG</option>
                  {pilotOptions.map((pilot) => (
                    <option key={pilot._id} value={pilot.trig}>
                      {pilot.trig}
                    </option>
                  ))}
                </select>
              </div>

              <button type="submit" id="submitopslimits">
                SUBMIT OPS LIMITS
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OpsLimits;
