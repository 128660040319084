import React, { useState, useEffect } from "react";

const URL = "https://server-ops-go51.onrender.com/7y4723y7hd/pilots/";

const Fcif = () => {
  const [inputValue, setInputValue] = useState("");
  const [fcif, setfcif] = useState("");
  const [read, setread] = useState("");
  const [pilotOptions, setPilotOptions] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await fetch(URL);
        const pilot = await res.json();
        setPilotOptions(pilot);
        setIsLoaded(true);
      } catch (err) {
        setError(err);
        setIsLoaded(true);
      }
    }

    fetchData();
  }, []);

  const updateFcif = async () => {
    try {
      if (fcif !== "" && read !== "") {
        const res = await fetch(`${URL}${inputValue}`, {
          method: "PATCH",
          body: JSON.stringify({
            fcif: fcif,
            read: read,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (res.ok) {
          window.location.href = "/";
        }
      } else if (fcif !== "") {
        const res = await fetch(`${URL}${inputValue}`, {
          method: "PATCH",
          body: JSON.stringify({
            fcif: fcif,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (res.ok) {
          window.location.href = "/";
        }
      } else if (read !== "") {
        const res = await fetch(`${URL}${inputValue}`, {
          method: "PATCH",
          body: JSON.stringify({
            read: read,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (res.ok) {
          window.location.href = "/";
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const inputParams = {
    type: "text",
    autoComplete: "off",
    id: "fcif",
    required: false,
  };

  return (
    <div className="cardcaps">
      <form
        onSubmit={(event) => {
          event.preventDefault();
          updateFcif();
        }}
      >
        <div className="columns has-text-center">
          <div className="column has-text-left">
            <p className="inline-flex">
              Type the last FCIF you read:
              <input
                placeholder="e.g 03/23"
                style={{ background: "white", marginLeft: "10px" }}
                onChange={(event) => setfcif(event.target.value)}
                {...inputParams}
              />
            </p>
            <label>FCIF</label>

            <p className="inline-flex">
              Type the last READ FILE you read:
              <input
                placeholder="e.g 01/23"
                style={{ background: "white", marginLeft: "10px" }}
                onChange={(event) => setread(event.target.value)}
                {...inputParams}
              />
            </p>
            <label>READ FILE</label>

            <br></br>

            <div className="submit-form">
              <div className="select">
                <select
                  required
                  value={inputValue}
                  style={{ width: "4cm" }}
                  onChange={(event) => setInputValue(event.target.value)}
                >
                  <option value="">SELECT TRIG</option>
                  {pilotOptions.map((pilot) => (
                    <option key={pilot._id} value={pilot.trig}>
                      {pilot.trig}
                    </option>
                  ))}
                </select>
              </div>

              <button type="submit" id="submit">
                UPDATE
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Fcif;
