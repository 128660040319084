
import checkDate from "../utils/checkDate"
import checkString from "../utils/checkString"
import WebEmbed from "../components/WebEmbed";

const PilotDetails = ({ pilots }) => {
  
  
  




  return (
    <div className="pilot-details">
    <div className="columns has-text-center">
      <div className="column has-text-left">
        <table>
          <thead>
            <tr>
              <th className="tableheader bg-black">PILOT</th>
              <th className="tableheader bg-red">CAPS</th>
              <th className="tableheader bg-red">OPSLIMITS</th>
              <th className="tableheader bg-red">FCIF</th>
              <th className="tableheader bg-grey">READ</th>
            </tr>
          </thead>
          <tbody>
            {pilots.sort((a, b) => a.order - b.order).map((pilot) => (
              <tr key={pilot._id}>
                <td className="trigcell" key={pilot.trig + "trig"}>{pilot.trig}</td>
                <td key={pilot.trig + "caps"}>{checkDate(pilot.caps, 8)}</td>
                <td key={pilot.trig + "opslimits"}>{checkDate(pilot.opslimits, 31)}</td>
                <td key={pilot.trig + "fcif"}>{checkString(pilot.fcif, "5/23", "red")}</td>
                <td key={pilot.trig + "read"}>{checkString(pilot.read, "1/22", "grey")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="column has-text-left">
        <WebEmbed weburl={"file://///riba5-dept/dept/RIBA5/20_GRUPO_OPERACIONAL/2035_E201_E301/01_UNAV/05_Shortucts/OPSGATE/QUONA_CONTROL.htm"}/>
      </div>
    </div>
  </div>

  )}

export default PilotDetails;