import 'bulma/css/bulma.min.css';
import { Link } from 'react-router-dom'

import React from "react";

const Navbar = () => {
  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      

      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-start">
        <Link className="navbar-item" href="/mboard" to="/mboard">
            M BOARD
          </Link>
          <Link className="navbar-item" href="/wx" to="/wx">
            WX
          </Link>
          <Link className="navbar-item" href="/" to="/">
            CONTROL
          </Link>
          <Link className="navbar-item" href="/quona" to="/quona">
            QUONA
          </Link>
          <Link className="navbar-item" href="/events" to="/events">
            EVENTS
          </Link>
          <Link className="navbar-item" href="/gina" to="/gina">
            GINA
          </Link>
          <Link className="navbar-item" href="/windy" to="/windy">
            WINDY
          </Link>
         
          
          
          
          <div className="navbar-item has-dropdown is-hoverable">
            <Link className="navbar-link" to="/atcd" href="/atcd">ATC</Link>
            <div className="navbar-dropdown">
              <Link className="navbar-item" href="/atcd" to="/atcd">TODAY</Link>
              <Link className="navbar-item" href="/atcnd" to="/atcnd">NEXT DAY</Link>
            
              
            </div>
            </div>
            <Link className="navbar-item" href="/contacts" to="/contacts">
            CONTACTS
          </Link>
          <div className="navbar-item has-dropdown is-hoverable">
            <Link className="navbar-link" to="/caps" href="/caps">B QUALS</Link>
            <div className="navbar-dropdown">
              <Link className="navbar-item" href="/caps" to="/caps">CAPS</Link>
              <Link className="navbar-item" href="/opslimits" to="/opslimits">OPS LIMITS</Link>
              <Link className="navbar-item" href="/updatefcif" to="/updatefcif">FCIF & READ</Link>
              
            </div>
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
            <Link className="navbar-link" to="/sof" href="/sof">SOF</Link>
            <div className="navbar-dropdown">
              <Link className="navbar-item" href="/sof" to="/sof">SOF</Link>
              <Link className="navbar-item" href="/twrcmr" to="/twrcmr">TWR CMR</Link>
              
              
            </div>
          </div>



        </div>
        

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};




export default Navbar;





