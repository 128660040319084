import React from 'react';
import ReactDOM from 'react-dom/client';
import "./styles.css";
import 'bulma/css/bulma.min.css';



import App from './App';
import { PilotsContextProvider } from './context/PilotsContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PilotsContextProvider>
      <App />
    </PilotsContextProvider>
  </React.StrictMode>
)