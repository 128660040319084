import React, { useState, useEffect } from "react";

const URL = "https://server-ops-go51.onrender.com/7y4723y7hd/pilots/";

const Caps = () => {
  const [inputValue, setInputValue] = useState("");
  const [pilotOptions, setPilotOptions] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await fetch(URL);
        const pilot = await res.json();
        setPilotOptions(pilot);
        setIsLoaded(true);
      } catch (err) {
        setError(err);
        setIsLoaded(true);
      }
    }

    fetchData();
  }, []);

  const updateCaps = async () => {
    try {
      const res = await fetch(`${URL}${inputValue}`, {
        method: "PATCH",
        body: JSON.stringify({
          caps: new Date(),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await res.json();
      console.log(data);
      //redirect to "/" - this should use reactrouter
      window.location.replace("/");
    } catch (err) {
      console.error(err);
    }
  };

  const inputParams = {
    type: "text",
    autoComplete: "off",
    id: "caps",
    required: true,
  };

  return (
    <div className="cardcaps">
      <form
        onSubmit={(event) => {
          event.preventDefault();
          updateCaps();
        }}
      >
        <div className="columns has-text-center">
          <div className="column has-text-left">
            <label>FIRE/ OVERHEAT/ FUEL LEAK (GROUND):</label>

            <p className="inline-flex">
              1.
              <input {...inputParams} pattern="^THROTTLE-OFF$" />
            </p>
            <br></br>

            <p className="inline-flex">
              2.
              <input {...inputParams} pattern="^JFS-OFF$" />
            </p>
            <br />

            <p className="inline-flex">
              3.
              <input {...inputParams} pattern="^FUEL MASTER SWITCH-OFF$" />
            </p>
            <label>GROUND EGRESS:</label>
            <p className="inline-flex">
              1.
              <input {...inputParams} pattern="^THROTTLE-OFF$" />
            </p>
            <br />
            <p className="inline-flex">
              2.
              <input {...inputParams} pattern="^SEAT-SAFE$" />
            </p>
            <br />
            <p className="inline-flex">
              3.
              <input
                {...inputParams}
                pattern="^BELT/KIT/HARNESS/G-SUIT-RELEASE$"
              />
            </p>
            <label>ABORT:</label>
            <p className="inline-flex">
              1.
              <input {...inputParams} pattern="^THROTTLE-IDLE$" />
            </p>
            <br />
            <p className="inline-flex">
              2.
              <input {...inputParams} pattern="^HOOK-DOWN$" /> (if required)
            </p>
            <label>AB MALFUNCTION ON TAKEOFF (TAKEOFF CONTINUED):</label>
            <p className="inline-flex">
              1.
              <input {...inputParams} pattern="^THROTTLE-MIL$" />
            </p>
            <br />
            <p className="inline-flex">
              2. <input {...inputParams} pattern="^STORES-JETTISON$" />
              (if required)
            </p>
            <label>ENGINE FAILURE ON TAKEOFF (TAKEOFF CONTINUED):</label>
            <p className="inline-flex">
              1.
              <input {...inputParams} pattern="^ZOOM$" />
            </p>
            <br />
            <p className="inline-flex">
              2.
              <input {...inputParams} pattern="^STORES-JETTISON$" />
              (if possible)
            </p>
            <br />
            <p className="inline-flex">
              3.
              <input {...inputParams} pattern="^EJECT$" />
            </p>
            <label>ENGINE FIRE ON TAKEOFF:</label>
            <p className="inline-flex">
              1.
              <input {...inputParams} pattern="^CLIMB$" />
            </p>
            <br />
            <p className="inline-flex">
              2.
              <input {...inputParams} pattern="^STORES-JETTISON$" />
              (if required)
            </p>
            <label>ENGINE STALL RECOVERY:</label>
            <p>If an AB Stall(s) occurs</p>
            <p className="inline-flex">
              1.
              <input {...inputParams} pattern="^THROTTLE-SNAP TO MIL$" />
            </p>
            <br />
            <p>If AB Stalls do not clear or stall(s) occurs below AB:</p>
            <p className="inline-flex">
              2.
              <input {...inputParams} pattern="^THROTTLE-IDLE$" />
            </p>
            <br />
            <p className="inline-flex">
              3.
              <input {...inputParams} pattern="^ANTI-ICE-OFF$" />
            </p>
            <br />
            <p>If Stalls continue or progress to a stagnation:</p>
            <p className="inline-flex">
              3.
              <input
                {...inputParams}
                pattern="^THROTTLE-OFF.INITIATE AIRSTART$"
              />
            </p>
            <br />

            <label>NWS HARD OVER:</label>
            <p className="inline-flex">
              1.
              <input {...inputParams} pattern="^NWS-DISENGAGE$" />
            </p>
            <br />
          </div>

          <div className="column">
            <label>LOW THRUST ON TAKEOFF / AT LOW ALTITUDE:</label>
            <p className="inline-flex">
              1.
              <input {...inputParams} pattern="^THROTTLE-AB$" />
            </p>
            <br />
            <p className="inline-flex">
              2.
              <input {...inputParams} pattern="^STORES-JETTISON$" />
              (if required)
            </p>
            <p>
              If PRI Thrust is insufficent to maintain level flight at safe
              altitude:
            </p>
            <p className="inline-flex">
              3.
              <input {...inputParams} pattern="^ENGINE CONTROL SWITCH-SEC$" />
            </p>
            <label>ENGINE FAILURE / AIRSTART:</label>
            <p className="inline-flex">
              1.
              <input {...inputParams} pattern="^ZOOM$" />
              (if at low alt.)
            </p>
            <br />
            <p className="inline-flex">
              2.
              <input {...inputParams} pattern="^STORES-JETTISON$" />
              (if required)
            </p>
            <br />
            <p className="inline-flex">
              3.
              <input {...inputParams} pattern="^THROTTLE-OFF$" />
            </p>
            <br />
            <p className="inline-flex">
              4.
              <input {...inputParams} pattern="^AIRSPEED-AS REQUIRED$" />
            </p>
            <br />
            <p className="inline-flex">
              When RPM is between
              <input
                {...inputParams}
                style={{ width: "1.5cm" }}
                pattern="^50-25$"
              />
            </p>
            <p className="inline-flex">
              percent with FTIT below
              <input
                {...inputParams}
                style={{ width: "1.5cm" }}
                pattern="^700$"
              />
            </p>
            <p className="inline-flex">degrees:</p>
            <br />

            <p className="inline-flex">
              5.
              <input {...inputParams} pattern="^THROTTLE-IDLE$" />
            </p>
            <br />
            <p className="inline-flex">
              6.
              <input style={{width:"350px"}}
                {...inputParams}
                pattern="^JFS-START 2 WHEN BELOW 20,000 FEET AND 400 KIAS$"
              />
            </p>
            <label>OUT-OF-CONTROL RECOVERY:</label>
            <p className="inline-flex">
              1.
              <input {...inputParams} pattern="^CONTROLS-RELEASE$" />
            </p>
            <br />
            <p className="inline-flex">
              2.
              <input {...inputParams} pattern="THROTTLE-IDLE" />
            </p>
            <br />
            <p>If in an inverted deep stall:</p>
            <p className="inline-flex">
              3.
              <input
                {...inputParams}
                pattern="^RUDDER-OPPOSITE YAW DIRECTION$"
              />
            </p>
            <br />
            <p>If still out of control:</p>
            <p className="inline-flex">
              4.
              <input {...inputParams} pattern="^MPO SWITCH-OVRD & HOLD$" />
            </p>
            <br />
            <p>After yaw rotation stops or is minimized:</p>
            <p className="inline-flex">
              5.
              <input {...inputParams} pattern="^STICK-CYCLE IN PHASE$" />
            </p>
            <label>BRAKE FAILURE:</label>
            <p>Consider a go-around / approach-end cable arrestment.</p>
            <p className="inline-flex">
              1.
              <input {...inputParams} pattern="^HOOK-DOWN$" />
              (if required)
            </p>
            <br />
            <p className="inline-flex">
              2.
              <input {...inputParams} pattern="^BRAKES CHANNEL-CHANGE$" />
            </p>
            <p>
              CAUTION - Release brakes prior to changing brake channels or
              turning ANTISKID off.
            </p>
            <p className="inline-flex">
              3.
              <input {...inputParams} pattern="^ANTI-SKID-OFF$" />
            </p>
            <p>
              CAUTION - Apply brakes with caution to avoid wheel lockup and
              blown tires.
            </p>
            <p className="inline-flex">
              4.
              <input {...inputParams} pattern="^NWS-ENGAGE$" /> (if required)
            </p>
            <label>COCKPIT OVERPRESSURIZATION ON GROUND:</label>
            <p className="inline-flex">
              1.
              <input {...inputParams} pattern="^THROTTLE-OFF$" />
            </p>
            <br />
            <p className="inline-flex">
              2.
              <input {...inputParams} pattern="^CANOPY-OPEN$" />
            </p>

            <div className="submit-form">
              <div className="select">
                <select
                  required
                  value={inputValue}
                  style={{ width: "4cm" }}
                  onChange={(event) => setInputValue(event.target.value)}
                >
                  <option value="">SELECT TRIG</option>

                  {pilotOptions.map((pilot) => (
                    <option key={pilot._id} value={pilot.trig}>
                      {pilot.trig}
                    </option>
                  ))}
                </select>
              </div>

              <button type="submit" id="submitcaps">
                SUBMIT CAPS
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Caps;
