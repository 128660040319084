import { useEffect } from "react";
import { usePilotsContext } from "../hooks/usePilotsContext";
// components
import PilotDetails from "../components/PilotDetails";
import PilotForm from "../components/PilotForm";

const Home = () => {
  const { pilots, dispatch } = usePilotsContext();

  const URL = "https://server-ops-go51.onrender.com/7y4723y7hd/pilots/";

  useEffect(() => {
    const fetchPilots = async () => {
      const response = await fetch(URL);
      const json = await response.json();

      if (response.ok) {
        dispatch({ type: "SET_PILOTS", payload: json });
      }
    };

    fetchPilots();
  }, [dispatch]);

  return (
    <div className="home">
      <div className="pilots">
        <PilotDetails pilots={pilots} />
      </div>
    </div>
  );
};

export default Home;
