import React from 'react';

const Events = () => {
  return (
    <div>
     
     <div><iframe src="file://///riba5-dept/dept/RIBA5/20_GRUPO_OPERACIONAL/2035_E201_E301/01_UNAV/05_Shortucts/OPSGATE/QUONA_EVENTS.htm" title="mboard" height={"950vh"} width={"100%"}></iframe>
     <iframe src="file://///riba5-dept/dept/RIBA5/20_GRUPO_OPERACIONAL/2035_E201_E301/01_UNAV/05_Shortucts/OPSGATE/QUONA_CMR.htm" title="mboard" height={"950vh"} width={"100%"}></iframe>
     <iframe src="file://///riba5-dept/dept/RIBA5/20_GRUPO_OPERACIONAL/2035_E201_E301/01_UNAV/05_Shortucts/OPSGATE/QUONA_CMRE.htm" title="mboard" height={"950vh"} width={"100%"}></iframe>
     <iframe src="file://///riba5-dept/dept/RIBA5/20_GRUPO_OPERACIONAL/2035_E201_E301/01_UNAV/05_Shortucts/OPSGATE/QUONA_BMC.htm" title="mboard" height={"950vh"} width={"100%"}></iframe>
     <iframe src="file://///riba5-dept/dept/RIBA5/20_GRUPO_OPERACIONAL/2035_E201_E301/01_UNAV/05_Shortucts/OPSGATE/QUONA_BMCE.htm" title="mboard" height={"950vh"} width={"100%"}></iframe></div>
    </div>
  );
};

export default Events;