const checkString = (specificString, validString, warncolor) => {
  
  if (!specificString) {
    return null;
  }

  
if (warncolor === "grey") {
  const className = specificString === validString ? "valid-cell" : "warning-cell";
  return <p className={className}>{specificString}</p>;
} 
else if (warncolor === "red") {
  const className = specificString === validString ? "valid-cell" : "expired-cell";
  return <p className={className}>{specificString}</p>;
} 




}

 

  
  

  


export default checkString;