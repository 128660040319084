import React from 'react';

const PdfEmbed = (props) => {
  return (
    <div>
      <embed src={props.pdfurl} type={"application/pdf"} width={"100%"} height={"980px"} />
    </div>
  );
};

export default PdfEmbed;