import { createContext, useReducer } from 'react'

export const PilotsContext = createContext()

export const pilotsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PILOTS':
      return { 
        pilots: action.payload 
      }
    case 'CREATE_PILOT':
      return { 
        pilots: [action.payload, ...state.pilots] 
      }
    default:
      return state
  }
}

export const PilotsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(pilotsReducer, { 
    pilots: []
  })
  
  return (
    <PilotsContext.Provider value={{ ...state, dispatch }}>
      { children }
    </PilotsContext.Provider>
  )
}