import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";


// pages & components
import Home from "./pages/Home";
import Caps from "./pages/caps";
import NotFound from "./pages/NotFound";
import Fcif from "./pages/fcif";
import OpsLimits from "./pages/opslimits";
import NavBar from "./components/Navbar";
import WebEmbed from "./components/WebEmbed";
import Quona from "./components/Quona";
import Events from "./components/Events";
import PdfEmbed from "./components/PdfEmbed";


const App = () => {
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const storedPassword = localStorage.getItem("password");

  useEffect(() => {
    const validpass = "mamas69";

    if (storedPassword === validpass) {
      setIsAuthenticated(true);
      console.log(storedPassword, "authenticated via stored password");
    }
  }, [storedPassword]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const validpass = "mamas69";
    const storedPassword = localStorage.getItem("password");
    if (storedPassword !== validpass) {
      window.localStorage.setItem("password", password);
      console.log("password set");
      if (password === validpass || storedPassword === validpass) {
        setIsAuthenticated(true);
        console.log("authenticated via input password");
      }
    }
  };

  return (
    <div>
      {isAuthenticated ? (
        <div>
          <div className="App">
            <BrowserRouter>
              <NavBar />
              <div className="pages">
                <Routes>
                  <Route path="/caps" element={<Caps />} />

                  <Route path="/opslimits" element={<OpsLimits />} />

                  <Route path="/updatefcif" element={<Fcif />} />
                  
                  <Route path="/atcd" element={<PdfEmbed pdfurl={"file://///riba5-dept/dept/RIBA5/20_GRUPO_OPERACIONAL/2099_AREA_TRABALHO/100_AREA_OPERACIONAL/05_ATC/01_Briefings/Briefing Semanal/ATC_DIA.pdf#zoom=page-fit"}/>} />
                  
                  <Route path="/atcnd" element={<PdfEmbed pdfurl={"file://///riba5-dept/dept/RIBA5/20_GRUPO_OPERACIONAL/2099_AREA_TRABALHO/100_AREA_OPERACIONAL/05_ATC/01_Briefings/Briefing Semanal/ATC_PROXDIA.pdf#zoom=page-fit"}/>} />
                  
                  <Route path="/sof" element={<WebEmbed weburl={"https://og51-sof.onrender.com/"} />} />
                  
                  <Route path="/twrcmr" element={<WebEmbed weburl={"http://10.5.247.225/aca/index.html"} />} />
                  
                  <Route path="/contacts" element={<PdfEmbed pdfurl="file://///riba5-dept/dept/RIBA5/20_GRUPO_OPERACIONAL/2035_E201_E301/01_UNAV/05_Shortucts/OPSGATE/CONTACTS.pdf#view=page-fit"/>} />
                  
                  <Route path="/gina" element={<WebEmbed weburl={"file://///riba5-dept/dept/RIBA5/20_GRUPO_OPERACIONAL/2035_E201_E301/01_UNAV/05_Shortucts/OPSGATE/GINA_2023.htm"} />} />
                  
                  <Route path="/windy" element={<WebEmbed weburl={"https://embed.windy.com/embed2.html?lat=40.212&lon=-7.130&detailLat=39.863&detailLon=-8.108&width=1680&height=980&zoom=7&level=surface&overlay=radar&product=radar&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=kt&metricTemp=%C2%B0C&radarRange=-6"} />} />
                  
                  <Route path="/events" element={<Events />} />
                  
                  <Route path="/quona" element={<Quona />} />
                                
                  <Route path="/mboard" element={<WebEmbed weburl={"file://///riba5-dept/dept/RIBA5/90_TRANSFER/05_Mission_Board_ReadOnlyMission%20Board%20Web%20Files/Today.html"} />} />
                  
                  <Route path="/wx" element={<WebEmbed weburl={"https://meteo.emfa.pt/NMET.html"} />} />

                  <Route path="/" element={<Home />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </div>
            </BrowserRouter>
          </div>
        </div>
      ) : (
        <div>
          <form onSubmit={handleSubmit}>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
            <button type="submit">ENTER</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default App;
