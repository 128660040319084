import { PilotsContext } from "../context/PilotsContext"
import { useContext } from "react"

export const usePilotsContext = () => {
  const context = useContext(PilotsContext)

  if(!context) {
    throw Error('usePilotsContext must be used inside an PilotsContextProvider')
  }

  return context
}