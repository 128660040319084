

import React, { useEffect } from "react";

const NotFound = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace("/");
    }, 1000);
  }, []);

  return (
    <div className="cardcaps">
      <div className="columns has-text-center">
        <h1>Refreshing...</h1>
      </div>
    </div>
  );
};

export default NotFound;