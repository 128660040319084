const checkDate = (specificDate, nDays) => {
  if (!specificDate) {
    return null;
  }

  const today = new Date();
  const expireDate = new Date(specificDate);
  expireDate.setDate(expireDate.getDate() + nDays);

  const className = today > expireDate ? "expired-cell" : "valid-cell";
  const date = new Date(specificDate).toISOString().slice(0, 10);

  return <p className={className}>{date}</p>;
};

export default checkDate;